<template>
  <Layout class="main_layout">
    <Sider class="layout_sider" v-if="!isIframe">
      <div
        class="layout_logo"
        v-if="type"
        :class="{ light: $route.query.theme === 'dark' }"
      >
        <img :src="logoUrl" alt="" />
      </div>
      <Menu
        ref="myMenu"
        class="layout_menu"
        :active-name="activeName"
        theme="light"
        width="auto"
        :open-names="openNames"
        @on-select="selectMenuFun"
      >
        <Submenu
          v-for="(item, index) in menuListArr"
          :key="index"
          :name="item.name"
        >
          <template slot="title">
            <Icon :type="item.icon" />
            {{ item.title }}
          </template>
          <MenuItem
            v-for="(ite, ind) in item.children"
            :key="ind"
            :name="ite.name"
          >
            <Icon :type="ite.icon" />
            {{ ite.title }}
          </MenuItem>
        </Submenu>
      </Menu>
    </Sider>
    <Layout>
      <HeaderTop v-if="!isIframe && type"></HeaderTop>
      <Breadcrumb class="bread_crumb_div" v-if="!isIframe">
        <BreadcrumbItem v-for="item in breadcrumbItemArr" :key="item">
          {{ $t(item) }}
        </BreadcrumbItem>
      </Breadcrumb>
      <Content class="layout_content" :class="isIframe ? 'no_padding' : ''">
        <router-view class="layout_content_bg" />
      </Content>
    </Layout>
  </Layout>
</template>
<script>
import HeaderTop from "@/components/HeaderTop.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      type: true,
      activeName: "dashboard",
      openNames: ["IoTManagement"],
      menuListArr: [
        {
          name: "IoTManagement",
          icon: "md-folder",
          title: "IoT Management",
          children: [
            {
              name: "dashboard",
              icon: "ios-home",
              title: "IoT Dashboard",
            },
            {
              name: "device",
              icon: "md-options",
              title: "Device Profiles",
            },
            {
              name: "gateways",
              icon: "md-wifi",
              title: "Gateways",
            },
            {
              name: "applications",
              icon: "ios-apps",
              title: "App & Sensors",
            },
          ],
        },
        {
          name: "DataProcessing",
          icon: "ios-analytics",
          title: "Data Processing",
          children: [
            {
              name: "dataProcessing",
              icon: "ios-analytics",
              title: "Data Processing",
            },
          ],
        },
        {
          name: "DataViewer",
          icon: "ios-stats",
          title: "Data Viewer",
          children: [
            {
              name: "dataViewer",
              icon: "ios-stats",
              title: "Data Viewer",
            },
          ],
        },
      ],
      breadcrumbItemArr: [],
    };
  },
  computed: {
    ...mapGetters({
      isIframe: "getAppIsIframe",
    }),
    logoUrl() {
      let query = this.$route.query;
      let theme = query.theme || "light";
      let Neuron_Logo_Black = require("../public/img/Neuron_Logo_Black.png");
      let Neuron_Logo_White = require("../public/img/Neuron_Logo_White.png");
      return theme === "light" ? Neuron_Logo_Black : Neuron_Logo_White;
    },
  },
  methods: {
    init() {
      this.checkIframePaths();
      let path = this.$route.path;
      path = path.substring(1);
      if (path) {
        this.selectMenuFun(path);
      }
      let key = this.$route.query.type;
      if (key === 'view') {
        this.type = false;
      } else {
        this.type = true;
      }
    },
    checkIframePaths() {
      let path = IOT_CONFIG.path,
        paths = {
          dataProcessing: IOT_CONFIG.dataProcessing,
          dataViewer: IOT_CONFIG.dataViewer,
        };
      for (let k in path) {
        const item = path[k];
        paths[
          k
        ] = `${IOT_CONFIG.iotIframeUrl}${IOT_CONFIG.iotRouter}/${IOT_CONFIG.tenantId}/${item}`;
      }
      IOT_CONFIG.paths = paths;
    },
    selectMenuFun(name) {
      for (let i = 0; i < this.menuListArr.length; i++) {
        let item = this.menuListArr[i];
        if (name === item.name) {
          // console.log(this.menuListArr[i].subTitle, name);
          this.breadcrumbItemArr = [this.menuListArr[i].name];
          this.activeName = name;
        }
        if (item.children) {
          item.children.map((subItem) => {
            if (subItem.name === name) {
              this.openNames = [item.name];
              this.breadcrumbItemArr = [item.name, subItem.name];
              this.activeName = name;
              this.$nextTick(() => {
                this.$refs.myMenu.updateOpened();
              });
            }
          });
        }
      }
      this.$router.push({
        path: name,
        query: this.$route.query,
      });
    },
  },
  created() {
    let path = this.$route.path;
    let name = path.replace("/", "");
    this.selectMenuFun(name, path);
  },
  mounted() {},
  created() {
    this.init();
  },
  components: {
    HeaderTop,
  },
};
</script>
<style lang='less'>
.layout_content {
  &.no_padding {
    padding: 0 !important;
  }
  button {
    font-family: "RobotoBold";
  }
  .layout_content_bg {
    height: 100%;
  }
}
</style>
