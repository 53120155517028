<template>
  <Header>
    <div class="header_top_left"></div>
    <div class="header_top_right">
      <Icon
        class="settings_icon"
        :size="18"
        type="md-settings"
        @click="showSettingBtn"
      />
      <Dropdown @on-click="userInfoFun">
        <a href="javascript:void(0)">
          <Icon type="md-contact" />
          {{ userName }}
          <Icon type="ios-arrow-down"></Icon>
        </a>
        <DropdownMenu slot="list">
          <!-- <DropdownItem name="editPassword">Edit Password</DropdownItem>
          <DropdownItem name="userInfo">User Info</DropdownItem> -->
          <DropdownItem name="logout">Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <Drawer :title="$t('settings')" :closable="true" v-model="settingsModel">
      <div v-for="(item, index) in settingsArr" :key="index">
        <Divider orientation="left">{{ $t("theme") }}</Divider>
        <Row :gutter="10">
          <Col :span="12" v-for="(ite, ind) in item.list" :key="ite.name">
            <Button
              :type="ite.active ? 'primary' : 'default'"
              long
              @click="themeClickFun(ite.name, ind)"
              >{{ $t(ite.name) }} <Icon :type="ite.icon"></Icon
            ></Button>
          </Col>
        </Row>
      </div>
      <Divider orientation="left">{{ $t("language") }}</Divider>
      <div style="text-align: center">
        <RadioGroup
          v-model="defaultLanguage"
          type="button"
          button-style="solid"
          @on-change="changeLanguage"
        >
          <Radio label="en">English</Radio>
          <Radio label="zh">简体</Radio>
          <Radio label="tw">繁体</Radio>
        </RadioGroup>
      </div>
    </Drawer>
  </Header>
</template>
<script>
export default {
  name: "HeaderTop",
  data() {
    return {
      showUserInfo: false,
      showPasswordEdit: false,
      userName: "Admin",
      settingsModel: false,
      settingsArr: [
        {
          title: "Theme",
          list: [
            {
              name: "light",
              icon: "md-sunny",
              active: true,
            },
            {
              name: "dark",
              icon: "md-moon",
              active: false,
            },
          ],
        },
      ],
      defaultLanguage: "en",
      showEditForm: false,
    };
  },
  methods: {
    init() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
      this.userName = userInfo.name || userInfo.username;
      this.initLanguage();
    },
    initLanguage() {
      let lan = this.$route.query.language || "en";
      if (lan != this.defaultLanguage) this.changeLanguage(lan);
      this.defaultLanguage = lan;
    },
    changeLanguage(name) {
      switch (name) {
        case "zh":
          this.$i18n.locale = "zh";
          break;
        case "tw":
          this.$i18n.locale = "tw";
          break;
        default:
          this.$i18n.locale = "en";
          break;
      }
      // console.log(this.$router.currentRoute)
      let query = Object.assign(
        JSON.parse(JSON.stringify(this.$router.currentRoute.query)),
        { language: name }
      );
      this.$router.push({
        query: query,
      });
    },
    showSettingBtn() {
      this.settingsModel = true;
      let name = this.$route.query.theme;
      let ind = 0;
      if (name == "dark") {
        ind = 1;
      } else if (name === "light") {
        ind = 0;
      }
      this.themeClickFun(name, ind);
    },
    themeClickFun(name, ind) {
      myVue.$emit("changeThemeEmit", name);

      let arr = this.settingsArr[0].list;
      for (let i = 0; i < arr.length; i++) {
        arr[i].active = false;
      }
      arr[ind].active = true;
      let key = this.$route.query.theme;
      if (key != name) {
        this.$router.push({
          query: Object.assign(
            JSON.parse(JSON.stringify(this.$router.currentRoute.query)),
            { theme: name }
          ),
        });
      }
    },
    userInfoFun(v) {
      let query = {};
      switch (v) {
        case "logout":
          this.$store.dispatch("signOut");
          this.$router.push({
            path: "/",
          });
          break;
        case "editPassword":
          this.showPasswordEdit = true;
          query = {
            isUser: true,
          };
          this.$router.push({
            query: Object.assign(this.$route.query, query),
          });
          break;
        case "userInfo":
          let username = sessionStorage.getItem("username");
          query = {
            isUser: true,
            username: username,
          };
          this.$router.push({
            query: Object.assign(this.$route.query, query),
          });
          this.showUserInfo = true;
          break;
        default:
          break;
      }
    },
    updatePasswordResult() {
      this.showPasswordEdit = false;
    },
  },
  created() {},
  mounted() {
    this.init();
  },
  components: {},
};
</script>
<style lang="less">
.header_top_right {
  float: right;
  a {
    color: #75779a;
  }
  .settings_icon {
    margin-right: 10px;
  }
}
</style>
